import classNames from 'classnames/bind';
import styles from '@/styles/superstack/base/Box.module.scss';
const cx = classNames.bind(styles);

import PropTypes from 'prop-types';
import StackImage from '@/src/component/superstack/stacks/StackImage';
import IconX from '@/asset/superstack/svgs/x.svg';
import CircleArrowLeft from '@/asset/superstack/svgs/circle-arrow-left.svg';
import { ChipClose } from '@/src/component/superstack/base/Chip';
import { useStaticData } from '@/src/utils/hooks/superstack/useStaticData';
import { useState, useEffect } from 'react';

import { ButtonLinkRear } from '@/src/component/superstack/base/ButtonLink';
import { StackCard } from '@/src/component/superstack/base/box/Stack';
import { CultureReviewResultBoxSummary } from '@/src/component/superstack/cultures/CultureReviewResultBox';
import useCultureReviewResultExist from '@/src/utils/hooks/superstack/useCultureReviewResultExist';
import { useRecoilValue } from 'recoil';
import { mobileState } from '@/src/utils/hooks/useMobileSize';

const BoxStacks = ({ stackDetails }) => {
  return (
    <div className={cx('box-stacks')}>
      {stackDetails.map((stackDetail) => (
        <StackItemNormal
          key={stackDetail.stack.name}
          flag={stackDetail.stack.flag}
          name={stackDetail.stack.name}
          requiredYear={stackDetail.required_year}
        />
      ))}
    </div>
  );
};

const BoxStacksCutoff = ({ stackDetails, cutoff = 2, ...props }) => {
  const cutoffedNum = stackDetails.length - cutoff;
  return (
    <div className={cx('box-stacks-cutoff')} {...props}>
      {stackDetails.slice(0, cutoff).map((stackDetail) => (
        <StackItemNormal
          key={stackDetail.stack.name}
          flag={stackDetail.stack.flag}
          name={stackDetail.stack.name}
          requiredYear={stackDetail.required_year}
        />
      ))}
      {cutoffedNum > 0 && <span className={cx('cutoffed-num')}>+{cutoffedNum}</span>}
    </div>
  );
};

const BoxStacksRequiredYear = ({ stacks, setStacks }) => {
  const stacks_ = stacks.map((stack) => (
    <StackItemNormalRequiredYear
      key={stack.name}
      flag={stack.flag}
      name={stack.name}
      requiredYear={stack.requiredYear}
      stacks={stacks}
      setStacks={setStacks}
      isSelectedList={true}
    />
  ));
  return (
    <div className={cx('box-stacks-simple')}>
      <div className={cx('stacks-container')}>{stacks_}</div>
    </div>
  );
};

const BoxStacksSimple = ({ stacks, setStacks, removable = true, cx = null }) => {
  if (!cx) {
    cx = classNames.bind(styles);
  }

  const stacks_ =
    stacks &&
    stacks.map((stack) => (
      <StackItemSimple
        key={stack.name}
        flag={stack.flag}
        name={stack.name}
        stacks={stacks}
        setStacks={setStacks}
        isSelectedList={true}
        removable={removable}
      />
    ));
  return (
    <div className={cx('box-stacks-simple')}>
      <div className={cx('stacks-container')}>{stacks_}</div>
    </div>
  );
};

const StackItemNormal = ({ flag, name, requiredYear, ...props }) => {
  const displayYear = requiredYear > 0 ? <span>ㆍ{requiredYear}년</span> : '';

  return (
    <div className={cx('stack-item-normal')} {...props}>
      <StackImage flag={flag} width={16} height={16} layout="fixed" />
      <div className={cx('content-with-icon')}>
        <span>{name}</span>
        {displayYear}
      </div>
    </div>
  );
};

const StackItemNormalRequiredYear = ({
  flag,
  name,
  requiredYear,
  isSelectedList = false,
  removable = true,
  ...props
}) => {
  const displayYear = requiredYear > 0 ? <span>ㆍ{requiredYear}년</span> : '';

  const deleteSelectedStack = () => {
    {
      if (isSelectedList === true && props.setStacks) {
        props.setStacks(props.stacks.filter((stacks) => stacks.name !== name));
      }
    }
  };

  return (
    <div className={cx('stack-item-simple')}>
      <div className={cx(['content-box'])} style={{ display: 'flex' }}>
        <StackImage flag={flag} width={16} height={16} layout="fixed" />
        <div className={cx('content-with-icon')} style={{ display: 'flex', alignItems: 'center' }}>
          <div>{name}</div>
          <div>{displayYear}</div>
          <div
            className={cx(['stack-item-X', removable ? 'clickable' : ''])}
            onClick={deleteSelectedStack}
          >
            {isSelectedList ? <IconX style={{ width: '12px', height: '12px' }} /> : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

const StackItemSimple = ({
  flag,
  name,
  selected = false,
  isSelectedList = false,
  searched = '',
  removable = true,
  clickable = false,
  ...props
}) => {
  const deleteSelectedStack = () => {
    if (isSelectedList === true && props.setStacks) {
      props.setStacks(props.stacks.filter((stacks) => stacks.name !== name));
    }
  };

  const stackName = name.toLowerCase();
  if (stackName.includes(searched.toLowerCase())) {
    return (
      <div
        className={cx(['stack-item-simple', selected ? 'stack-item-simple--selected' : ''])}
        {...props}
      >
        <div className={cx(['content-box', clickable ? 'clickable' : ''])}>
          <StackImage flag={flag} width={24} height={24} layout="fixed" />
          <div className={cx('content-with-img')}>
            {name}
            {removable && (
              <div className={cx(['stack-item-X', 'clickable'])} onClick={deleteSelectedStack}>
                {isSelectedList ? <IconX style={{ width: '12px', height: '12px' }} /> : ''}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};
const EmptySelectedStackRangeBox = () => {
  return (
    <div className={cx(['empty-selected-stack'])}>
      <div>
        <div className={cx(['empty-selected-stack-arrow'])}>
          <CircleArrowLeft />
        </div>
        <div style={{ padding: '4px' }}>
          <p>기술 검색 후</p>
          <p>적합한 프로젝트를 찾아볼 수 있는</p> 경력을 설정할 수 있습니다.
        </div>
      </div>
    </div>
  );
};

const MEmptySelectedStackRangeBox = () => {
  return (
    <div className={cx(['mobile-empty-selected-stack', 'mobile-empty-selected-stack-range'])}>
      <div className={cx(['mobile-empty-selected-stack-text'])}>
        <div>
          <p>기술 검색 후</p>
          <p>적합한 프로젝트를 찾아볼 수 있는</p> 경력을 설정할 수 있습니다.
        </div>
      </div>
    </div>
  );
};

const MEmptySelectedStackBox = ({ title }) => {
  return (
    <div className={cx(['mobile-empty-selected-stack'])}>
      <div className={cx(['mobile-empty-selected-stack-text'])}>
        <div>
          {title === '기술 스택 필터' && (
            <div>
              <p>기술 검색 후</p>
              <p>적합한 프로젝트를 찾아볼 수 있는</p>경력을 설정할 수 있습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MSelectedStackChips = ({ filteredStacks, setFilteredStacks }) => {
  const [flags, setFlags] = useState([]);
  const { stacks: stataicStacks = [] } = useStaticData();

  useEffect(() => {
    if (Array.isArray(filteredStacks)) {
      // filteredStacks: array
      setFlags(
        filteredStacks.map((filteredStack) => {
          return filteredStack?.flag;
        }),
      );
    } else {
      // filteredStacks: object
      setFlags(Object.keys(filteredStacks));
    }
  }, [JSON.stringify(filteredStacks)]);

  const deleteFilteredStack = (flag) => {
    if (Array.isArray(filteredStacks)) {
      setFilteredStacks(filteredStacks.filter((filteredStack) => filteredStack?.flag !== flag));
    } else {
      if (Object.prototype.hasOwnProperty.call(filteredStacks, flag)) {
        const newFilteredItems = { ...filteredStacks };
        delete newFilteredItems[flag];
        setFilteredStacks(newFilteredItems);
      }
    }
  };
  return (
    <section style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
      {flags.map((flag) => {
        const displayName = stataicStacks.find((staticStack) => staticStack.flag === flag)?.name;
        if (!displayName) {
          return null;
        }
        return (
          <ChipClose
            key={flag}
            mode={'filled'}
            size={'xl'}
            widthClose={8}
            heightClose={8}
            onClick={() => {
              deleteFilteredStack(flag);
            }}
          >
            <div className={cx('stack-img-box')}>
              <StackImage flag={flag} width={20} height={20} layout="fixed" />
              <div>{displayName}</div>
            </div>
          </ChipClose>
        );
      })}
    </section>
  );
};
const MSelectedSigunguChips = ({
  filteredItems,
  setFilteredItems,
  checkIsAllSelectedSido,
  setCheckIsAllSelectedSido,
}) => {
  const deleteSido = (sidoSigungu) => {
    const sigunguSplit = sidoSigungu.split(';');
    if (sigunguSplit[1] === '전체') {
      setFilteredItems(
        filteredItems.filter((val) => {
          return val.split(';')[0] !== sigunguSplit[0];
        }),
      );
      setCheckIsAllSelectedSido(
        checkIsAllSelectedSido.filter((val) => {
          return val !== sigunguSplit[0];
        }),
      );
    } else {
      setFilteredItems(filteredItems.filter((val) => val !== sidoSigungu));
    }
  };
  return Object.keys(filteredItems).map((item, idx) => {
    const sigunguSplit = filteredItems[item].split(';');
    if (sigunguSplit[1] === '전체') {
      if (!checkIsAllSelectedSido.includes(sigunguSplit[0])) {
        setCheckIsAllSelectedSido(checkIsAllSelectedSido.concat(sigunguSplit[0]));
      }
      return (
        <div key={idx} className={cx(['mobile-selected-stack-chip'])}>
          <div style={{ marginRight: '12px' }}>
            {sigunguSplit[0]} {sigunguSplit[1]}
          </div>
          <div
            onClick={() => {
              deleteSido(filteredItems[item]);
            }}
          >
            <IconX style={{ width: '12px', height: '12px', fill: '#616161' }} />
          </div>
        </div>
      );
    } else if (!checkIsAllSelectedSido.includes(sigunguSplit[0])) {
      return (
        <div key={idx} className={cx(['mobile-selected-stack-chip'])}>
          <div style={{ marginRight: '12px' }}>
            {sigunguSplit[0]} {sigunguSplit[1]}
          </div>
          <div
            onClick={() => {
              deleteSido(filteredItems[item]);
            }}
          >
            <IconX style={{ width: '12px', height: '12px', fill: '#616161' }} />
          </div>
        </div>
      );
    }
  });
};

const BoxStackCultureSummary = ({
  jobStacks,
  companyId,
  handleClickStackAll,
  handleClickCultureAll,
  ...props
}) => {
  const { data: { data: { data: hasCultureReviewResult } = {} } = {} } =
    useCultureReviewResultExist(companyId);
  const requiredStacks = jobStacks.filter((jobStack) => jobStack.is_required);
  const canShowCulture = !!hasCultureReviewResult;
  const isMobile = useRecoilValue(mobileState);
  return (
    <section className={cx('box-stack-culture-summary')} {...props}>
      <article className={cx('summary')}>
        <div className={cx('title-wrapper')}>
          <p className={cx('title')}>필수 스택</p>
          <ButtonLinkRear
            mode={'borderless'}
            size={'lg'}
            style={{ backgroundColor: '#fafafa' }}
            onClick={handleClickStackAll}
          >
            모두 보기
          </ButtonLinkRear>
        </div>
        <div className={cx('stack-container')} style={{ maxHeight: '62px', marginTop: '12px' }}>
          {requiredStacks
            .filter((jobStack) => {
              return jobStack.is_required;
            })
            .map((jobStack) => {
              return (
                <StackCard
                  userStacks={{}}
                  jobStack={jobStack}
                  key={jobStack.stack.name}
                  size={'xs'}
                />
              );
            })}
        </div>
      </article>
      <article className={cx('summary')}>
        <div className={cx('title-wrapper')}>
          <p className={cx('title')}>컬처코드</p>
          {canShowCulture && (
            <ButtonLinkRear
              mode={'borderless'}
              size={'lg'}
              style={{ backgroundColor: '#fafafa' }}
              onClick={handleClickCultureAll}
            >
              모두 보기
            </ButtonLinkRear>
          )}
        </div>
        <CultureReviewResultBoxSummary
          companyId={companyId}
          style={{ marginTop: isMobile ? '16px' : '12px' }}
        />
      </article>
    </section>
  );
};

BoxStacks.propTypes = {
  stacks: PropTypes.array,
};

StackItemSimple.propTypes = {
  flag: PropTypes.string,
  name: PropTypes.string,
};

export {
  BoxStacks,
  BoxStacksCutoff,
  BoxStacksSimple,
  StackItemSimple,
  EmptySelectedStackRangeBox,
  MEmptySelectedStackBox,
  BoxStacksRequiredYear,
  MEmptySelectedStackRangeBox,
  MSelectedStackChips,
  MSelectedSigunguChips,
  BoxStackCultureSummary,
};
