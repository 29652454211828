import { useState } from 'react';
import useCultureReviewResult from '@/src/utils/hooks/superstack/useCultureReviewResult';
import classNames from 'classnames/bind';
import styles from '@/styles/superstack/cultures/CultureReviewResult.module.scss';
import { Chip } from '@/src/component/superstack/base/Chip';
import useStaticCultureV1 from '@/src/utils/hooks/superstack/useStaticCultureV1';
import { CultureLinks } from '@/data/superstack/CultureLinks';
import useCulture from '@/src/utils/hooks/superstack/useCulture';
import IconArrowDown from '@/asset/superstack/svgs/icon-arrow-down.svg';
import IconArrowUp from '@/asset/superstack/svgs/icon-arrow-up.svg';
import LinkTag from '@/src/component/LinkTag';
import { ButtonLinkRear } from '@/src/component/superstack/base/ButtonLink';
import { ButtonBlock } from '@/src/component/superstack/base/Button';
import AngleDown from '@/asset/superstack/svgs/angle-down.svg';
import ImgCultureEmpty from '@/asset/superstack/svgs/img-culture-empty.svg';

const cx = classNames.bind(styles);

const CULTURE_MULTIPLE_ITEMS = ['dev_cultures'];

const CultureReviewResultBoxSummary = ({ companyId, ...props }) => {
  const { data: { data: cultureReviewResult = {} } = {}, isLoading } =
    useCultureReviewResult(companyId);
  const { data: { data: staticCulture = {} } = {}, isLoading: isCultureLoading } =
    useStaticCultureV1();
  const { data: { data: { answer: companyAnswer = {} } = {} } = {} } = useCulture(companyId);

  const checkHasAnswer = (isLoading, cultureReviewResult) => {
    return !isLoading && cultureReviewResult?.date_aggregated;
  };

  if (isCultureLoading) {
    return null;
  }
  return (
    <section className={cx('box-culture-review-result')} {...props}>
      {checkHasAnswer(isLoading, cultureReviewResult) ? (
        <ResultBox
          staticCulture={staticCulture}
          answer={cultureReviewResult.answer}
          companyAnswer={companyAnswer}
          isSummary={true}
        />
      ) : (
        <EmptyResultBoxSummary staticCulture={staticCulture} />
      )}
    </section>
  );
};

const CultureReviewResultBox = ({
  companyId,
  needComment = false,
  needUpdateLink = false,
  useShowMore = false,
  needTitle = true,
  ...props
}) => {
  const { data: { data: cultureReviewResult = {} } = {}, isLoading } =
    useCultureReviewResult(companyId);
  const { data: { data: staticCulture = {} } = {}, isLoading: isCultureLoading } =
    useStaticCultureV1();
  const { data: { data: { answer: companyAnswer = {} } = {} } = {} } = useCulture(companyId);

  const [showMore, setShowMore] = useState(false);

  const checkHasAnswer = (isLoading, cultureReviewResult) => {
    return !isLoading && cultureReviewResult?.date_aggregated;
  };

  if (isCultureLoading) {
    return null;
  }
  const Content = ({ hasAnswer }) => {
    return (
      <>
        {hasAnswer ? (
          <ResultBox
            staticCulture={staticCulture}
            answer={cultureReviewResult.answer}
            companyAnswer={companyAnswer}
            commentIncluded={needComment}
            showLess={useShowMore && !showMore}
          />
        ) : (
          <EmptyResultBox />
        )}
        {hasAnswer && useShowMore && !showMore && (
          <ButtonBlock
            size={'lg'}
            mode={'outline'}
            onClick={() => {
              setShowMore(true);
            }}
            style={{ width: '100%', marginBottom: '32px' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                color: '#616161',
              }}
            >
              <p>컬처코드 더 보기</p>
              <span>
                <AngleDown width={18} height={18} fill={'#616161'} />
              </span>
            </div>
          </ButtonBlock>
        )}
      </>
    );
  };
  return (
    <section className={cx('box-culture-review-result')} {...props}>
      <div className={cx('title-wrapper')}>
        {needTitle && <p className={cx('title')}>컬처코드</p>}
        {needUpdateLink && (
          <LinkTag
            href={
              CultureLinks(companyId).find((url) => url.name === 'user_culture_review_router').url
            }
            isNextPage={true}
          >
            <ButtonLinkRear mode={'borderless'} size={'lg'}>
              내 리뷰 업데이트
            </ButtonLinkRear>
          </LinkTag>
        )}
      </div>
      <Content hasAnswer={checkHasAnswer(isLoading, cultureReviewResult)} />
    </section>
  );
};

const ResultBox = ({
  staticCulture,
  answer,
  companyAnswer,
  commentIncluded = false,
  isSummary = false,
  showLess = false,
}) => {
  let companyAnswerObj;
  try {
    companyAnswerObj = JSON.parse(companyAnswer);
  } catch (e) {
    companyAnswerObj = {};
  }

  let items = Object.entries(staticCulture)
    .filter(([name]) => !CULTURE_MULTIPLE_ITEMS.includes(name))
    .filter(([name]) => !!answer[name]);
  if (isSummary) {
    items = items.slice(0, 1);
  } else if (showLess) {
    items = items.slice(0, 3);
  }
  return (
    <>
      {items.map(([name, staticItem]) => {
        return (
          <AnswerResultWrapper
            key={name}
            name={name}
            staticItem={staticItem}
            answer={answer?.[name]}
            answerComment={commentIncluded ? companyAnswerObj?.[`${name}_c`] : null}
            isSummary={isSummary}
          />
        );
      })}
      {!isSummary &&
        !showLess &&
        CULTURE_MULTIPLE_ITEMS.map((name) => {
          return (
            <MultipleAnswerResultWrapper
              key={name}
              staticItem={staticCulture[name]}
              answer={answer?.[name]}
            />
          );
        })}
    </>
  );
};

const AnswerResultWrapper = ({ staticItem, answer, answerComment, isSummary = false }) => {
  const { q, concise } = staticItem;
  const objs = Object.entries(concise)
    .filter(([key]) => key !== 'q')
    .map(([key, val]) => {
      return { text: val, num: answer[key] || 0 };
    });
  const [isCommentOpen, setIsCommentOpen] = useState(true);
  const IconSummaryArrow = isCommentOpen ? IconArrowDown : IconArrowUp;
  const ChartWrapper = isSummary
    ? CultureResultBarChartWrapperSimple
    : CultureResultBarChartWrapper;
  return (
    <div className={cx(['result-item', isSummary ? 'is-summary' : ''])}>
      <div className={cx('q-wrapper')}>
        <p>{concise?.q || q}</p>
      </div>
      <ChartWrapper objs={objs} />
      {answerComment && (
        <div className={cx('comment-wrapper')}>
          <details open={true}>
            <summary
              onClick={() => {
                setIsCommentOpen(!isCommentOpen);
              }}
            >
              <p>인사담당자 한마디</p>
              <IconSummaryArrow width={16} height={16} fill={'#9E9E9E'} />
            </summary>
            <p className={cx('comment')}>{answerComment}</p>
          </details>
        </div>
      )}
    </div>
  );
};

const MultipleAnswerResultWrapper = ({ staticItem, answer }) => {
  const { q, concise, ...values } = staticItem;
  const hasAnswer = () => {
    return answer && Object.keys(answer).length > 0;
  };
  return hasAnswer() ? (
    <div className={cx('result-item-wrapper')}>
      <div className={cx('result-item')} style={{ alignItems: 'center' }}>
        <div className={cx('q-wrapper')}>
          <div>{concise?.q || q}</div>
        </div>
        <div className={cx('multiple-answers')}>
          {Object.entries(answer)
            .filter(([, val]) => val > 0)
            .map(([key]) => {
              return (
                <Chip
                  mode={'outline'}
                  size={'xl'}
                  key={key}
                  clickable={false}
                  style={{ color: '#212121', outlineColor: '#34C759', borderRadius: '46px' }}
                >
                  {values[key]}
                </Chip>
              );
            })}
        </div>
      </div>
    </div>
  ) : null;
};

const EmptyResultBox = () => {
  return (
    <div className={cx('empty-result-box')}>
      <ImgCultureEmpty />
      <p>컬처코드 수집중입니다.</p>
    </div>
  );
};

const EmptyResultBoxSummary = ({ staticCulture }) => {
  let items = Object.entries(staticCulture)
    .filter(([name]) => !CULTURE_MULTIPLE_ITEMS.includes(name))
    .slice(0, 1);
  return (
    <>
      {items.map(([name, staticItem]) => {
        return (
          <EmptyAnswerResultWrapper
            key={name}
            name={name}
            staticItem={staticItem}
            isSummary={true}
          />
        );
      })}
    </>
  );
};

const EmptyAnswerResultWrapper = ({ staticItem, isSummary = false }) => {
  const { q, concise } = staticItem;
  const objs = Object.entries(concise)
    .filter(([key]) => key !== 'q')
    .map(([, val]) => {
      return { text: val, num: 0 };
    });
  const ChartWrapper = isSummary
    ? CultureResultBarChartWrapperSimple
    : CultureResultBarChartWrapper;
  return (
    <div className={cx(['result-item', isSummary ? 'is-summary' : ''])}>
      <div className={cx('q-wrapper')}>
        <p>{concise?.q || q}</p>
      </div>
      <ChartWrapper objs={objs} />
    </div>
  );
};

const CultureResultBarChartWrapper = ({ objs }) => {
  const { text: topText, num: topNum } = objs[0];
  const { text: bottomText, num: bottomNum } = objs[1];

  const totalOccupancy = topNum + bottomNum;
  const topProp = (topNum / totalOccupancy) * 100;
  const bottomProp = (bottomNum / totalOccupancy) * 100;
  const isEmpty = !topNum && !bottomNum;
  const topMajority = topProp >= bottomProp;
  const bottomMajority = topProp <= bottomProp;

  return (
    <section className={cx('culture-result-bar-wrapper')}>
      <div className={cx('bar-wrapper')}>
        <div className={cx(['bar-chart', isEmpty ? 'empty' : ''])}>
          <div className={cx(['bar', topMajority ? 'majority' : ''])}>
            <p>{topText}</p>
            <p>
              {isEmpty ? '0%' : `${topProp.toFixed(0)}%`} ({topNum}명)
            </p>
          </div>
          <div
            className={cx([
              'bar',
              topMajority && bottomMajority ? 'equal' : bottomMajority ? 'majority' : '',
            ])}
          >
            <p>{bottomText}</p>
            <p>
              {isEmpty ? '0%' : `${bottomProp.toFixed(0)}%`} ({bottomNum}명)
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const CultureResultBarChartWrapperSimple = ({ objs }) => {
  const { text: leftText, num: leftNum } = objs[0];
  const { text: rightText, num: rightNum } = objs[1];

  const totalOccupancy = leftNum + rightNum;
  const leftProp = (leftNum / totalOccupancy) * 100;
  const rightProp = (rightNum / totalOccupancy) * 100;

  const leftBarWidth = `${leftProp.toFixed(0)}%`;
  const rightBarWidth = `${rightProp.toFixed(0)}%`;

  const isEmpty = !leftNum && !rightNum;
  const leftMajority = leftProp >= rightProp;

  return (
    <section className={cx('culture-result-bar-wrapper-simple')}>
      <div className={cx('bar-wrapper')}>
        <div className={cx(['bar-chart', isEmpty ? 'empty' : ''])}>
          {isEmpty && <div className={cx(['bar', 'left'])} style={{ width: '100%' }} />}
          {leftBarWidth !== '0%' && (
            <div
              className={cx(['bar', 'left', leftMajority ? 'majority' : ''])}
              style={{ width: leftBarWidth }}
            />
          )}
          {rightBarWidth !== '0%' && (
            <div
              className={cx(['bar', 'right', leftMajority ? '' : 'majority'])}
              style={{ width: rightBarWidth }}
            ></div>
          )}
        </div>
      </div>
      <div className={cx('answer-wrapper')}>
        <p className={cx([!isEmpty && leftMajority ? 'majority' : ''])}>
          {leftText}({leftNum}명)
        </p>
        <p className={cx([leftMajority ? '' : isEmpty ? '' : 'majority'])}>
          {rightText}({rightNum}명)
        </p>
      </div>
    </section>
  );
};

export { CultureReviewResultBox, CultureReviewResultBoxSummary };
