import useSWRImmutable from 'swr/immutable';
import { api } from '@/src/utils/api';
import { CultureLinks } from '@/data/superstack/CultureLinks';

export default function useStaticCultureV1() {
  const fetcher = async (...args) =>
    api.get(...args).then((res) => {
      switch (res.status) {
        case 200:
          return res;
        default:
          return res;
      }
    });
  const { data, error, mutate } = useSWRImmutable(
    CultureLinks().find((url) => url.name === 'culture_survey_data_v1').url,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnMount: true,
    },
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
