import classNames from 'classnames/bind';
import styles from '@/styles/superstack/base/Button.module.scss';
import PropTypes from 'prop-types';
import { Button, ButtonBlock } from './Button';
import IconArrowRight from '@/asset/superstack/svgs/icon-arrow-right.svg';
import IconArrowLeft from '@/asset/superstack/svgs/icon-arrow-left.svg';
import { colorWhite, colorBlue50, colorGray70 } from '@/styles/superstack/base/Button.module.scss';

const ButtonLink = ({ mode, size, isRear, isBack, isBlock = false, ...props }) => {
  const cx = classNames.bind(styles);
  let iconColor;
  let iconWidth, iconHeight;
  const cnIsRear = isRear ? 'content-with-icon-rear' : 'content-with-icon-front';
  const Button_ = isBlock ? ButtonBlock : Button;

  switch (mode) {
    case 'filled':
      iconColor = colorWhite;
      break;
    case 'stroke':
      iconColor = colorBlue50;
      break;
    case 'text':
      iconColor = colorGray70;
      break;
    case 'borderless':
      iconColor = colorBlue50;
      break;
  }
  switch (size) {
    case 'xl':
      iconWidth = 6.88;
      iconHeight = 13.75;
      break;
    case 'lg':
      iconWidth = 6.19;
      iconHeight = 12.37;
      break;
    default:
      iconWidth = 4.81;
      iconHeight = 9.61;
  }

  const iconProps = {
    width: iconWidth,
    height: iconHeight,
    fill: iconColor,
  };
  const Icon = isBack ? <IconArrowLeft {...iconProps} /> : <IconArrowRight {...iconProps} />;
  return (
    <Button_ mode={mode} size={size} {...props}>
      <div className={cx('content-box')}>
        {!isRear && Icon}
        <div className={cx(cnIsRear)}>{props.children}</div>
        {isRear && Icon}
      </div>
    </Button_>
  );
};

const ButtonLinkBlock = ({ mode, size, isRear, isBack, ...props }) => {
  return (
    <ButtonLink mode={mode} size={size} isRear={isRear} isBack={isBack} isBlock={true} {...props} />
  );
};

const ButtonLinkRear = ({ mode, size, ...props }) => {
  return (
    <ButtonLink mode={mode} size={size} isRear={true} {...props}>
      {props.children}
    </ButtonLink>
  );
};

ButtonLinkRear.propTypes = {
  /**
   * What mode
   */
  mode: PropTypes.oneOf(['default', 'filled', 'stroke', 'text', 'borderless']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  children: PropTypes.string,
};

ButtonLinkRear.defaultProps = {
  mode: 'filled',
  size: 'md',
  children: '버튼',
};

const ButtonLinkFront = ({ mode, size, ...props }) => {
  return (
    <ButtonLink mode={mode} size={size} isRear={false} {...props}>
      {props.children}
    </ButtonLink>
  );
};

ButtonLinkFront.propTypes = {
  /**
   * What mode
   */
  mode: PropTypes.oneOf(['default', 'filled', 'stroke', 'text']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  children: PropTypes.string,
};

ButtonLinkFront.defaultProps = {
  mode: 'filled',
  size: 'md',
  children: '버튼',
};

const ButtonLinkBackFront = ({ mode, size, ...props }) => {
  return (
    <ButtonLink mode={mode} size={size} isRear={false} isBack={true} {...props}>
      {props.children}
    </ButtonLink>
  );
};

ButtonLinkBackFront.propTypes = {
  /**
   * What mode
   */
  mode: PropTypes.oneOf(['default', 'filled', 'stroke', 'text', 'cancel']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  children: PropTypes.string,
};

ButtonLinkBackFront.defaultProps = {
  mode: 'filled',
  size: 'md',
  children: '버튼',
};

export { ButtonLinkRear, ButtonLinkFront, ButtonLinkBackFront, ButtonLinkBlock };
