import useSWRImmutable from 'swr/immutable';
import { api, BASE_API_URL } from '@/src/utils/api';

function useStaticData() {
  const fetcher = async (...args) => {
    const res = await api.get(...args);
    return res;
  };
  const { data, error, mutate } = useSWRImmutable(
    BASE_API_URL + '/superstack/static_data',
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );
  return {
    roles: data && data.data.roles,
    levels: data && data.data.levels,
    stacks: data && data.data.stacks,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export { useStaticData };
